<template>
  <div class="map-container">
    <div v-if="currentMap < 7" class="btn-prev btn-vertical btn-prev-vertical" @click="nextMap">
      <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
    </div>
    <div
      v-if="currentMap > 0"
      class="btn-next btn-vertical btn-vertical-dark btn-next-vertical"
      @click="prevMap"
    >
      <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
    </div>
    <div class="map-controls"></div>
    <router-view ref="mapChildView" id="map-child-view" />
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";
export default {
  data() {
    return {};
  },
  methods: {
    prevMap() {
      const vm = this;
      const tl = new gsap.timeline();
      tl.to("#map-child-view", {
        duration: 1,
        y: "-=10vh",
        opacity: 0,
        ease: Power2.easeInOut,
        onComplete: function() {
          if (vm.currentMap > 0) {
            var currentMap = vm.currentMap;
            currentMap -= 1;
            vm.$store.commit("updateCurrentMap", currentMap);
            vm.$router.push({ name: `Map-${vm.currentMap}` });
          }
        },
      });
    },
    nextMap() {
      const vm = this;
      const tl = new gsap.timeline();
      tl.to("#map-child-view", {
        duration: 1,
        y: "+=10vh",
        opacity: 0,
        ease: Power2.easeInOut,
        onComplete: function() {
          if (vm.currentMap < 8) {
            var currentMap = vm.currentMap;
            currentMap += 1;
            vm.$store.commit("updateCurrentMap", currentMap);
            vm.$router.push({ name: `Map-${vm.currentMap}` });
          }
        },
      });
    },
  },
  computed: {
    currentMap() {
      return this.$store.state.gallery1.currentMap;
    },
  },
  mounted() {
    document.querySelector("html,body").style.overflow = "hidden";
  }
};
</script>

<style>
.btn-vertical-dark svg {
  fill: #5a4a42 !important;
}
</style>
